var Handlebars = require("../../../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div id=\"topBar\"></div>\n<main id=\"main\"></main>\n\n<div id=\"modalWrapper\" class=\"hidden\" tabindex=\"0\">\n  <div id=\"modal\">\n    <div id=\"modalContent\"></div>\n    <button class=\"close\" title=\""
    + alias3((lookupProperty(helpers,"i18n")||(depth0 && lookupProperty(depth0,"i18n"))||alias2).call(alias1,"close",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":8,"column":33},"end":{"line":8,"column":49}}}))
    + "\">&#215;</a>\n  </div>\n</div>\n\n<div id=\"svelteModal\"></div>\n\n<div id=\"flashMessage\" title=\""
    + alias3((lookupProperty(helpers,"i18n")||(depth0 && lookupProperty(depth0,"i18n"))||alias2).call(alias1,"offline_help",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":15,"column":30},"end":{"line":15,"column":53}}}))
    + "\">\n  "
    + alias3((lookupProperty(helpers,"icon")||(depth0 && lookupProperty(depth0,"icon"))||alias2).call(alias1,"bolt",{"name":"icon","hash":{},"data":data,"loc":{"start":{"line":16,"column":2},"end":{"line":16,"column":17}}}))
    + "\n  <span>"
    + alias3((lookupProperty(helpers,"i18n")||(depth0 && lookupProperty(depth0,"i18n"))||alias2).call(alias1,"you are offline",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":17,"column":8},"end":{"line":17,"column":34}}}))
    + "</span>\n</div>\n";
},"useData":true});