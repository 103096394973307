var Handlebars = require("../../../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<a id=\"back\">"
    + container.escapeExpression((lookupProperty(helpers,"icon")||(depth0 && lookupProperty(depth0,"icon"))||container.hooks.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),"arrow-left",{"name":"icon","hash":{},"data":data,"loc":{"start":{"line":1,"column":30},"end":{"line":1,"column":51}}}))
    + "</a>";
},"3":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "  <p class=\"grey\">"
    + container.escapeExpression((lookupProperty(helpers,"icon")||(depth0 && lookupProperty(depth0,"icon"))||alias2).call(alias1,"warning",{"name":"icon","hash":{},"data":data,"loc":{"start":{"line":4,"column":18},"end":{"line":4,"column":36}}}))
    + "&nbsp;"
    + ((stack1 = ((helper = (helper = lookupProperty(helpers,"warningText") || (depth0 != null ? lookupProperty(depth0,"warningText") : depth0)) != null ? helper : alias2),(typeof helper === "function" ? helper.call(alias1,{"name":"warningText","hash":{},"data":data,"loc":{"start":{"line":4,"column":42},"end":{"line":4,"column":59}}}) : helper))) != null ? stack1 : "")
    + "</p>\n";
},"5":function(container,depth0,helpers,partials,data) {
    var alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "  <label for=\"confirmationForm\">"
    + alias3((lookupProperty(helpers,"I18n")||(depth0 && lookupProperty(depth0,"I18n"))||alias2).call(alias1,(depth0 != null ? lookupProperty(depth0,"formLabel") : depth0),{"name":"I18n","hash":{},"data":data,"loc":{"start":{"line":7,"column":32},"end":{"line":7,"column":50}}}))
    + "</label>\n  <textarea id=\"confirmationForm\" placeholder=\""
    + alias3((lookupProperty(helpers,"I18n")||(depth0 && lookupProperty(depth0,"I18n"))||alias2).call(alias1,(depth0 != null ? lookupProperty(depth0,"formPlaceholder") : depth0),{"name":"I18n","hash":{},"data":data,"loc":{"start":{"line":8,"column":47},"end":{"line":8,"column":71}}}))
    + "...\"></textarea>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"canGoBack") : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":1,"column":0},"end":{"line":1,"column":62}}})) != null ? stack1 : "")
    + "\n<p>"
    + ((stack1 = ((helper = (helper = lookupProperty(helpers,"confirmationText") || (depth0 != null ? lookupProperty(depth0,"confirmationText") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"confirmationText","hash":{},"data":data,"loc":{"start":{"line":2,"column":3},"end":{"line":2,"column":25}}}) : helper))) != null ? stack1 : "")
    + "</p>\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"warningText") : depth0),{"name":"if","hash":{},"fn":container.program(3, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":3,"column":0},"end":{"line":5,"column":7}}})) != null ? stack1 : "")
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"formLabel") : depth0),{"name":"if","hash":{},"fn":container.program(5, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":6,"column":0},"end":{"line":9,"column":7}}})) != null ? stack1 : "")
    + "\n<a id=\"no\" class=\"button grey radius bold\" tabindex=\"0\">"
    + alias4((lookupProperty(helpers,"i18n")||(depth0 && lookupProperty(depth0,"i18n"))||alias2).call(alias1,(depth0 != null ? lookupProperty(depth0,"no") : depth0),{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":11,"column":56},"end":{"line":11,"column":67}}}))
    + "</a>\n<a id=\"yes\" class=\"button "
    + alias4(((helper = (helper = lookupProperty(helpers,"yesButtonClass") || (depth0 != null ? lookupProperty(depth0,"yesButtonClass") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"yesButtonClass","hash":{},"data":data,"loc":{"start":{"line":12,"column":26},"end":{"line":12,"column":44}}}) : helper)))
    + " radius bold\" tabindex=\"0\">"
    + alias4((lookupProperty(helpers,"i18n")||(depth0 && lookupProperty(depth0,"i18n"))||alias2).call(alias1,(depth0 != null ? lookupProperty(depth0,"yes") : depth0),{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":12,"column":71},"end":{"line":12,"column":83}}}))
    + "</a>\n\n<div class=\"check\"></div>\n";
},"useData":true});